import Button from "../button";
import ResponsiveImage from "../modules/responsive-image";

export default function CbBanner10({block}) {
    return (
        <>
            <div className="container">
                <div className="flex flex-wrap -tw-mx-3">

                    <div className="tw-hidden lg:tw-block tw-px-3 lg:tw-w-1/2">
                        <div className="tw-p-40 tw-mx-auto">
                            {block.image && (<ResponsiveImage
                                image={block.image}
                            />)}
                        </div>
                    </div>

                    <div className="tw-px-3 tw-w-full lg:tw-w-1/2 tw-pt-20">
                        {block.title && (
                            <div
                                className="cb--banner_10__title"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}

                        {block.subtitle && (
                            <p class="cb--banner_10__subtitle">
                                {block.subtitle}
                            </p>
                        )}

                        <div className="lg:tw-hidden tw-p-10">
                            {block.image && (<ResponsiveImage
                                image={block.image}
                            />)}
                        </div>

                        <div className="tw-bg-gray-100 lg:tw-bg-transparent -tw-mx-6 tw-px-6 tw-pt-12 tw-pb-16 lg:tw-p-0 lg:tw-m-0">

                            {block.text && (
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{__html: block.text}}
                                />
                            )}

                            <div className="tw-pb-10 tw-py-4 tw-text-[18px] lg:tw-text-[25px]">
                                {block.price.text_above_price && (
                                    <span>{block.price.text_above_price}</span>
                                )}
                                <div className="tw-flex tw-gap-6 tw-items-baseline">
                                    {block.price.price && (
                                        <span className="cb--banner_10__price">{block.price.price}</span>
                                    )}
                                    {block.price.text_after_price && (
                                        <span>{block.price.text_after_price}</span>
                                    )}
                                </div>
                            </div>

                            {block.button && (
                                <Button
                                    button={block.button}
                                    classes="button "
                                />
                            )}

                            {block.usps && block.usps.length && (
                                <div className="tw-flex tw-flex-col tw-gap-y-2 tw-pt-10">
                                    {block.usps.map((usp) => {
                                        return (<div className="tw-flex tw-gap-x-2 tw-gap-y-1" key={usp.usp}>
                                            <i className="fas fa-check-circle mt-5 mr-10"/>
                                            <span>{usp.usp}</span>
                                        </div>);
                                    })}
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
