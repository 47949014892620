import ResponsiveImage from "../modules/responsive-image";
import Link from "next/link";

export default function CbProducts2({block}) {
    return (
        <>
            <div className="cb--products_2__section-with-dark-background">
                <div className="container">
                    {block.title && (
                        <div
                            className="cb--products_2__title"
                            dangerouslySetInnerHTML={{__html: block.title}}
                        />
                    )}

                    {block.content && (
                        <div
                            className="cb--products_2__content tw-pb-10 xl:tw-max-w-[60%]"
                            dangerouslySetInnerHTML={{__html: block.content}}
                        />
                    )}
                </div>
            </div>

            <div className="container">
                <div className="tw-flex tw-flex-wrap -tw-mx-4">
                    {block.products && block.products.length &&
                        block.products.map((product) => (
                            <div
                                className="cb--products_2__product tw-relative tw-w-full md:tw-w-1/2 lg:tw-w-1/3 tw-px-4 tw-font-normal">
                                {product.image &&
                                    <div className="tw-aspect-square tw-rounded-3xl tw-overflow-hidden">
                                        <ResponsiveImage
                                            image={product.image}
                                            classes="tw-object-cover tw-w-full tw-h-full"
                                        />
                                    </div>
                                }
                                    <div className="tw-pt-10">
                                        {product.title && (
                                            <div className="cb--products_2__product__title"
                                                 dangerouslySetInnerHTML={{__html: product.title}}></div>
                                        )}
                                        {product.text && (
                                            <div dangerouslySetInnerHTML={{__html: product.text}}></div>
                                        )}
                                        {product.price && (
                                            <div className="tw-flex tw-gap-2 tw-items-end tw-leading-none tw-pb-8">
                                                <span
                                                    className="tw-text-[26px] tw-text-green tw-font-medium">{product.price}</span>
                                                {product.text_after_price && (
                                                    <span>{product.text_after_price}</span>
                                                )}
                                            </div>
                                        )}
                                        {product.usps && product.usps.length &&
                                            <div className="tw-flex tw-flex-col tw-gap-y-2 tw-pb-10 tw-text-[16px]">
                                                {product.usps.map((usp) => (
                                                    <div className="tw-flex tw-items-center tw-gap-x-2">
                                                        <i className="far fa-plus-circle shrink-0 tw-text-green"/>
                                                        <span key={usp.text}>{usp.text}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>

                                {product.link && (
                                    <Link href={product.link.url} passHref>
                                        <a target={product.link.target} className="tw-absolute tw-inset-0 tw-size-full"></a>
                                    </Link>
                                )}
                            </div>
                        ))
                    }

                    <div className="tw-w-full md:tw-w-1/2 lg:tw-w-1/3 tw-px-4">
                        <div
                            className="cb--products_2__cta tw-flex tw-flex-col tw-justify-between tw-p-10 tw-pt-16 tw-rounded-3xl tw-h-full">
                            <div>
                                {block.cta.title && (
                                    <div className="tw-text-[30px] tw-font-bold tw-leading-tight tw-pb-10"
                                         dangerouslySetInnerHTML={{__html: block.cta.title}}></div>
                                )}
                                {block.cta.button && (
                                    <a href={block.cta.button.url}
                                       className="cb--products_2__cta__button"
                                       dangerouslySetInnerHTML={{__html: block.cta.button.title}}></a>
                                )}
                            </div>
                            {block.cta.image &&
                                <div className="tw-aspect-square tw-overflow-hidden tw-mt-10">
                                    <ResponsiveImage
                                        image={block.cta.image}
                                        classes="tw-object-cover tw-w-full tw-h-full tw-rounded-3xl"
                                    />
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
